import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import LoginLogout from "./loginLogout";
import { Config } from "../config/config";
import "../App.css";
import { AuthContext } from "react-oauth2-code-pkce";

const Conf = new Config(
	process.env.REACT_APP_ENVIRONMENT_NAME
		? process.env.REACT_APP_ENVIRONMENT_NAME
		: "staging"
);

const AdminHomePage = () => {
	const { token } = useContext(AuthContext);
	return (
		<>
			<LoginLogout />
			<Typography variant="h4" noWrap component="div">
				Welcome to the Care360 Admin page. You are in the{" "}
				{process.env.REACT_APP_ENVIRONMENT_NAME} environment.
			</Typography>
			<Link href={`${Conf.vars.BASE_URL}/invoice`}>
				<Button variant="contained">Upload Invoices.</Button>
			</Link>
			<br />
			<br />
			<Link href={`${Conf.vars.BASE_URL}/carepayQuestionnaire`}>
				<Button variant="contained">
					Complete Carepay Questionnaire.
				</Button>
			</Link>
			<br />
			<br />
			<Link href={`${Conf.vars.BASE_URL}/reviewQuestionnaire`}>
				<Button variant="contained">
					Review Carepay Questionnaires
				</Button>
			</Link>
			<br />
			<br />
			<Link href={`${Conf.vars.BASE_URL}/addCarepayCustomer`}>
				<Button variant="contained">Add a Customer to Carepay</Button>
			</Link>
			<br />
			<br />
			<Link href={`${Conf.vars.BASE_URL}/updateDap`}>
				<Button variant="contained">Update DAPs for new MPIR</Button>
			</Link>
			<br />
			<br />
			<Link href={`${Conf.vars.BASE_URL}/viewSync`}>
				<Button variant="contained">View and Run Jobs</Button>
			</Link>
			<br />
			<br />
		</>
	);
};

export default React.memo(AdminHomePage);
