import React, { useContext, ChangeEvent, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';

import { getUserDetailsForEmail, createCarepayAccount } from '../services/carepayService';


const VALID_EMAIL_REGEX: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const AddCarepayCustomer = () => {
  const { token } = useContext(AuthContext);
  const [ email, setEmail ] = useState('');
  const [ username, setUsername ] = useState('');
  const [ firstName, setFirstName ] = useState('');
  const [ surname, setSurname ] = useState('');
  const [ message, setMessage ] = useState('');

  // When email looks like an email we look it up
  useEffect(() => {
    if (token != undefined && VALID_EMAIL_REGEX.test(email)) {
      getDetailsFromCarepayApi(token, email);
    }
  }, [email]);

  const getDetailsFromCarepayApi = async (token: string, email: string) => {
    const getUserResponse = await getUserDetailsForEmail(token, email);
    if(getUserResponse && getUserResponse.Users && getUserResponse.Users.length > 0) {
      setUsername(getUserResponse.Users[0].Username);
      getUserResponse.Users[0].Attributes.forEach((attribute: any) => {
        if (attribute.Name === 'given_name') {
          setFirstName(attribute.Value);
        } else if (attribute.Name === 'family_name') {
          setSurname(attribute.Value);
        }
      });
    }
  }
  
  const enrollInCarepay = async (event: any) => {
    const response = await createCarepayAccount(token, username);
    if (response && response.code) {
      setMessage(response.code);
    }
  }

  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  }

  return (
    <>
      <div style={{overflow: 'hidden'}}>
        <Box sx={{width: 40, height:1000}} style={{'float': 'left'}}/>
        <Box sx={{width: 700}}  style={{overflow: 'hidden'}} >
          <>
            <Typography>
              Please enter the email for the customer you want to add:
            </Typography>
            <FormControl fullWidth>
              <TextField
                id="admin-invoice-upload-email-type"
                value={email}
                label="Customer Email"
                variant="standard"
                onChange={handleChangeEmail}
                style={{marginTop: '10px'}}
                >
              </TextField>
            </FormControl>
            <Typography>
              FirstName: {firstName}<br/>
              Surname: {surname}<br/>
              Username: {username}<br/>
            </Typography>
            <Button
              onClick={enrollInCarepay}
            >
              Enroll in Carepay
            </Button>
            {
              (message !== '') && (
                <Typography>
                  Created Carepay Account with Reference Code : {message}
                </Typography>
              )
            }
          </>
        </Box>
      </div>
    </>
  );
}

export default React.memo(AddCarepayCustomer);