import React, { useContext, ChangeEvent, useEffect } from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

import { ILineItems } from '../interfaces/invoice';
import '../App.css';

export const LINE_ITEM_CATEGORIES = [
  'Allied Health',
  'Beauty Services',
  'Accommodation Fees',
  'Sundries'
]

const LineItems = ({
  lineItem,
  index,
  handleChange
} : {
  lineItem: ILineItems,
  index: number
  handleChange: any
}) => {
  const [amountInCents, setAmountInCents] = React.useState(lineItem.amountInCents);
  const [quantity, setQuantity] = React.useState(lineItem.quantity);
  const [lineTotal, setLineTotal] = React.useState(lineItem.lineTotal);
  const [description, setDescription] = React.useState(lineItem.description);
  const [ categories, setCategories ] = React.useState([])

  // Note event is most closely React.ChangeEvent<HTMLInputElement> but MaterialUI doesnt fully implement...;
  const handleAmountInCentsLineItem = (event: any) => {
    setAmountInCents(event.target.value);
    handleChange({amountInCents: event.target.value, quantity, lineTotal, description, categories}, index);
  }
  const handleDescriptionLineItem = (event: any) => {
    setDescription(event.target.value);
    handleChange({amountInCents, quantity, lineTotal, description: event.target.value, categories}, index);
  }
  const handleQuantityLineItem = (event: any) => {
    setQuantity(event.target.value);
    handleChange({amountInCents, quantity: event.target.value, lineTotal, description, categories}, index);
  }
  const handleLineTotalLineItem = (event: any) => {
    setLineTotal(event.target.value);
    handleChange({amountInCents, quantity, lineTotal: event.target.value, description, categories}, index);
  }

  const handleChangeCategories = (event: any) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const categoriesArray = (typeof value === 'string') ? value.split(',') : value
    setCategories(categoriesArray);
    handleChange({amountInCents, quantity, lineTotal, description, categoriesArray}, index);
  };


  return (
    <Box style={{marginLeft: '15px', width: '50%'}} >
      <Typography style={{marginTop: '15px'}}>
        Line item {index+1}
      </Typography>
      <FormControl fullWidth style={{marginLeft:'10px'}}>
        <InputLabel id="admin-invoice-upload-description-type"></InputLabel>
        <TextField
          id="admin-invoice-upload-description-type"
          value={description}
          label="Description"
          variant="standard"
          onChange={handleDescriptionLineItem}
          >
        </TextField>
      </FormControl>
      <FormControl fullWidth style={{marginTop: '5px', marginLeft:'10px'}}>
        <TextField
          id="admin-invoice-upload-amountInCents-type"
          value={amountInCents}
          label="Amount (in Cents)"
          variant="standard"
          onChange={handleAmountInCentsLineItem}
          >
        </TextField>
      </FormControl>
      <FormControl fullWidth style={{marginTop: '10px', marginLeft:'10px'}}>
        <TextField
          id="admin-invoice-upload-quantity-type"
          value={quantity}
          label="Quantity"
          variant="standard"
          onChange={handleQuantityLineItem}
          >
        </TextField>
      </FormControl>
      <FormControl fullWidth style={{marginTop: '10px', marginLeft:'10px'}}>
        <TextField
          id="admin-invoice-upload-amountInCents-type"
          value={lineTotal}
          label="Line Total"
          variant="standard"
          onChange={handleLineTotalLineItem}
          >
        </TextField>
      </FormControl>
      <FormControl fullWidth style={{marginTop: '10px', marginLeft:'10px'}}>
        <InputLabel id="demo-multiple-chip-label">Categories</InputLabel>
        <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={categories}
            onChange={handleChangeCategories}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {LINE_ITEM_CATEGORIES.map((name) => (
              <MenuItem
                key={name}
                value={name}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
      </FormControl>
      <Divider style={{marginTop:'5px'}}/>
    </Box>
  )
};

export default React.memo(LineItems);
