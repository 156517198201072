import React, { useContext, ChangeEvent, useEffect } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Typography from "@mui/material/Typography";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { postInvoice, uploadInvoicePdf, getContacts, getRules } from '../services/carepayService';
import { IRules } from "../interfaces/rules";
import { ILineItems } from "../interfaces/invoice";
import { AddContact } from './addContact';
import '../App.css';
import LineItems from './LineItems';

const UploadInvoice = () => {
  const { token } = useContext(AuthContext);

  const [trustRef, setTrustRef] = React.useState('');
  const [approvalType, setApprovalType] = React.useState('');
  const [invoiceStatus, setInvoiceStatus] = React.useState('');
  const [invoiceId, setInvoiceId] = React.useState('');
  const [dateCreated, setDateCreated] = React.useState('');
  const [dueDate, setDueDate] = React.useState('');
  const [file, setFile] = React.useState<File>();
  const [contactId, setContactId] = React.useState('');
  const [contacts, setContacts] = React.useState([]);
  const [addContactModal, setAddContactModal] = React.useState(false);
  const [preApprovalRules, setPreApprovalRules ] = React.useState<IRules>();
  const [lineItems, setLineItems] = React.useState<ILineItems[]>([
    {
      description: '',
      amountInCents: 0,
      quantity: 0,
      lineTotal: '0'
    }
  ]);

  // Empty 'useEffect' approximates componentDidMount
  useEffect(() => {
    if (token !== undefined) {
      getContactsAndUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When TrustRef reaches 6 characters we start scraping for rules
  useEffect(() => {
    if (trustRef.length >= 6 && token != undefined) {
      getRulesAndUpdate();
    }
  }, [trustRef])

  const getRulesAndUpdate = async() => {
    const getRulesResponse = await getRules(token, trustRef);
    console.log(getRulesResponse);
    if (getRulesResponse && getRulesResponse.data[0] && getRulesResponse.data[0].preApprovalRules) {
      setPreApprovalRules(getRulesResponse.data[0].preApprovalRules);
    }
  }

  const getContactsAndUpdate = async() => {
    const contactsResponse = await getContacts(token);
    setContacts(contactsResponse.contacts);
  }

  const submitInvoice = async () => {
    // Firstly upload invoice to s3 ..
    const s3Link = await uploadInvoicePdf(token, file);

    // Yeet to CarePay - TODO is make this environment aware
    const reqBody = {
      trustRef,
      invoiceId,
      linkToPdf: s3Link.invoiceUrl,
      approvalType,
      invoiceStatus,
      dateCreated: convertDateToString(new Date(dateCreated)),
      dueDate: convertDateToString(new Date(dueDate)),
      contactId: contactId,
      lineItems
    }

    postInvoice(token, reqBody).then(
      (res: any) => {console.log(JSON.stringify(res))}
    );
  }

  // Not event is most closely React.ChangeEvent<HTMLInputElement> but MaterialUI doesnt fully implement...;
  const handleApprovalType = (event: any) => {
    setApprovalType(event.target.value);
  }
  const handleInvoiceStatus = (event: any) => {
    setInvoiceStatus(event.target.value);
  }
  const handleTrustRef = (event: any) => {
    setTrustRef(event.target.value);
  }
  const handleInvoiceId = (event: any) => {
    setInvoiceId(event.target.value);
  }
  const handleDateCreated = (value: any) => {
    setDateCreated(value);
  }
  const handleDueDate = (value: any) => {
    setDueDate(value);
  }
  const handleContactChange = (event: any) => {
    setContactId(event.target.value);
  }

  const handleAddContactModalOpen = () => {
    setAddContactModal(true);
  }
  const handleAddContactModalClose = () => {
    setAddContactModal(false);
  }

  const handleFileUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleLineItemChange = (lineItemData: ILineItems, index: number) => {
    const tempLineItems = lineItems;
    tempLineItems[index] = lineItemData;
    setLineItems(tempLineItems);
  }

  const handleAddLineItem = () => {
    const emptyLineItem = {
      description: '',
      amountInCents: 0,
      quantity: 0,
      lineTotal: '0',
      categories: []
    }
    const tempLineItems = [... lineItems];
    tempLineItems.push(emptyLineItem);
    setLineItems(tempLineItems);
  }

  const renderRules = (
    <>
      {
        (preApprovalRules !== undefined) && (
          <>
            <Typography>
              Care360 preapproved value: ${parseInt(preApprovalRules?.care360Invoices.preApprovedValue)/100}<br/>
              Home Invoice Preapproved values:<br/>
                Required Fees: ${parseInt(preApprovalRules?.homeInvoices.requiredFees.preApprovedValue)/100}<br/>
                Other Invoice Preapproved Values:<br/>
              </Typography>
          </>
        )
      }
      {
        (preApprovalRules?.otherInvoices?.medicalInvoices?.preApprovedValue !== undefined) && (
          <>
            <Typography>
              Medical invoices: ${parseInt(preApprovalRules?.otherInvoices?.medicalInvoices?.preApprovedValue)/100}<br/>
            </Typography>
          </>
        ) 
      }
      {
        (preApprovalRules?.otherInvoices?.beautyInvoices?.preApprovedValue !== undefined) && (
          <>
            <Typography>
              Beauty invoices: ${parseInt(preApprovalRules?.otherInvoices?.beautyInvoices?.preApprovedValue)/100}<br/>
            </Typography>
          </>
        ) 
      }
      {
        (preApprovalRules?.otherInvoices?.healthAndWellnessInvoices?.preApprovedValue !== undefined) && (
          <>
            <Typography>
              Health and Wellness invoices: ${parseInt(preApprovalRules?.otherInvoices?.healthAndWellnessInvoices?.preApprovedValue)/100}<br/>
            </Typography>
          </>
        ) 
      }
    </>
  )

  return (
    <>
      <div style={{overflow: 'hidden'}}>
        <Box sx={{width: 40, height:1000}} style={{'float': 'left'}}/>
        <Box sx={{width: 700}}  style={{overflow: 'hidden'}} >
          <>
            <FormControl fullWidth>
              <TextField
                id="admin-invoice-upload-trustRef-type"
                value={trustRef}
                label="Trust Reference"
                variant="standard"
                onChange={handleTrustRef}
                style={{marginTop: '10px'}}
                >
              </TextField>
            </FormControl>
            {renderRules}
            <br/>
            <FormControl fullWidth>
              <TextField
                id="admin-invoice-upload-invoiceId-type"
                value={invoiceId}
                label="Invoice Id"
                variant="standard"
                onChange={handleInvoiceId}
                style={{marginTop: '10px'}}
                >
              </TextField>
            </FormControl>
            <br/>
            <Box style={{width:'80%'}}>
              <Typography style={{marginTop:'25px'}}>
                Line Items
              </Typography>
              {lineItems.map((lineItem, index) => (
                (
                <LineItems
                  lineItem={lineItem}
                  index={index}
                  handleChange={handleLineItemChange}
                />
              )))}
              <br/>
              <Button
                onClick={handleAddLineItem}
              >
                Add Line Item
              </Button>
            </Box>
            <br/>
            <FormControl fullWidth style={{marginTop: '10px'}}>
              <InputLabel id="admin-invoice-upload-linkToPdf-type"></InputLabel>
              {
                (
                  (file !== undefined) && (
                    <div>Invoice to Upload is: {file.name}</div>
                  )
                )
              }
              <Button
                variant="contained"
                component="label"
              >
                Upload Invoice PDF
                <input 
                  type="file" 
                  hidden 
                  accept='.pdf'
                  onChange={handleFileUploadChange}
                />
              </Button>
            </FormControl>
            <br/>
            <FormControl fullWidth style={{marginTop: '10px'}}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  value={dateCreated}
                  label="Date Created"
                  inputFormat='YYYY-MM-DD'
                  onChange={handleDateCreated}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <br/>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Due Date"
                  inputFormat='YYYY-MM-DD'
                  value={dueDate}
                  onChange={handleDueDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            <br/>
            <FormControl fullWidth style={{marginTop: '10px'}}>
              <InputLabel id="admin-invoice-upload-status-type">Invoice Status</InputLabel>
              <Select
                labelId="admin-invoice-upload-status-type-label"
                id="admin-invoice-upload-select-status-type"
                value={invoiceStatus}
                label="Invoice Status"
                onChange={handleInvoiceStatus}
                >
                  <MenuItem value={"PAID"}>Paid</MenuItem>
                  <MenuItem value={"AWAITING_PAYMENT"}>Awaiting Payment</MenuItem>
                  <MenuItem value={"AWAITING_APPROVAL"}>Awaiting Approval</MenuItem>
                  <MenuItem value={"REJECTED"}>Rejected</MenuItem>
              </Select>
            </FormControl>
            <br/>
            <FormControl fullWidth style={{marginTop: '10px'}}>
              <InputLabel id="admin-invoice-upload-approval-type">Approval Type</InputLabel>
              <Select
                labelId="admin-invoice-upload-approval-type-label"
                id="admin-invoice-upload-select-approval-type"
                value={approvalType}
                label="Approval Type"
                onChange={handleApprovalType}
                >
                  <MenuItem value={"MANUAL"}>Manual</MenuItem>
                  <MenuItem value={"AUTOMATIC"}>Automatic</MenuItem>
                </Select>
            </FormControl>
            <br/>
            <FormControl fullWidth style={{marginTop: '10px'}}>
              <InputLabel>Contact</InputLabel>
              <Select
                value={contactId}
                label="Contact"
                onChange={handleContactChange}
              >
                {
                  contacts.map((contact: any) =>
                    (
                      <MenuItem
                        value={contact.contactID}
                        key={contact.contactID}
                      >
                        {contact.name} {(contact.taxNumber !== undefined) && " - "+contact.taxNumber}
                      </MenuItem>
                    )
                  )
                }
              </Select>
              <Button
                variant="outlined"
                onClick={handleAddContactModalOpen}
                style={{marginTop: '5px'}}
              >
                  <AddIcon/>Add Contact
              </Button>
            </FormControl>
            <br/>
            <Button
              variant="contained"
              onClick={submitInvoice}
              style={{marginTop: '25px'}}
            >
              Submit Invoice
            </Button>
          </>
        </Box>
      </div>
      <Modal
        open={addContactModal}
        onClose={handleAddContactModalClose}
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
      >
        <AddContact/>
      </Modal>
    </>
  )
};

function convertDateToString(date: Date) {
  const yyyy = date.getFullYear()
  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();

  return [yyyy,
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join('-');
};

export default React.memo(UploadInvoice);
