import React, { useContext, ChangeEvent, useEffect } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';

import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { getCarepayApplicationsByStatus } from '../services/carepayService';
import { ICarepayApplicationPayload } from "../interfaces/carepayApplication";

const ReviewQuestionnaire = () => {

  const { token } = useContext(AuthContext);
  const [ applications, setApplications ] = React.useState([]);
  const [ expandedApplication, setExpandedApplication ] = React.useState<ICarepayApplicationPayload>();
  
  // Get Questionnaires that are awaiting approval.
  // Empty 'useEffect' approximates componentDidMount
  useEffect(() => {
    if (token !== undefined) {
      getQuestionnaires();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuestionnaires = async () => {
    const getApplicationsResponse = await getCarepayApplicationsByStatus(token, 'AWAITING_REVIEW');
    if (getApplicationsResponse !== undefined) {
      setApplications(getApplicationsResponse.data);
    }
  }

  function handleRowClick(application: any) {
    setExpandedApplication(application);
  }

  return (
    <>
      <div style={{overflow: 'hidden'}}>
        <Box sx={{width: 20, height:1000}} style={{'float': 'left'}}/>
        <Box sx={{width: 900}}  style={{overflow: 'hidden'}} >
          <TableContainer>
            <Table sx={{ width: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ApplicationRef</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  applications.map((application: any) => {
                    return (
                      <TableRow>
                          <TableCell>
                            {application.applicationRef}
                          </TableCell>
                          <TableCell>
                            {application.email}
                          </TableCell>
                          <TableCell>
                            {application.status}
                          </TableCell>
                          <TableCell>
                          <Button
                            onClick={() => {handleRowClick(application)}}
                          >
                            Review Application
                          </Button>
                          </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          {
            (
              (expandedApplication && expandedApplication.applicationRef !== '') && (
                <Typography>
                  Personal Info:<br/>
                    &emsp;Name: {expandedApplication.application.personalInfo.name}<br/>
                    &emsp;Email: {expandedApplication.application.personalInfo.email}<br/>
                    &emsp;Completing for: {expandedApplication.application.personalInfo.self}<br/>
                    &emsp;Relationship: {expandedApplication.application.personalInfo.relationship}<br/>
                    &emsp;EPOA?: {expandedApplication.application.personalInfo.epoa}<br/><br/>
                  
                  ACAT Assessement:<br/>
                    &emsp;Response Received: {expandedApplication.application.acatAssessment.responseReceived}<br/>
                    &emsp;Care Level Approved: {expandedApplication.application.acatAssessment.careLevel}<br/>
                    &emsp;Date of Assessment: {expandedApplication.application.acatAssessment.date}<br/>
                    &emsp;Needs Changes Since Assessement: {expandedApplication.application.acatAssessment.needsChanges}<br/><br/>

                  Income Assessement:<br/>
                    &emsp;Assessement Forms Received: {expandedApplication.application.incomeAssessement.assessmentReceived}<br/>
                    &emsp;Assessement Forms Completed: {expandedApplication.application.incomeAssessement.formsCompleted}<br/>
                    &emsp;Assessement Forms Submitted Date: {expandedApplication.application.incomeAssessement.dateFormsSubmitted}<br/>
                    &emsp;Assessement Form Result: {expandedApplication.application.incomeAssessement.formResult}<br/>
                    &emsp;Centerlink Authorised Representative: {expandedApplication.application.incomeAssessement.authorisedRepresentative}<br/><br/>

                  Personal Finances: <br/>
                    &emsp;Has Partner: {expandedApplication.application.personalFinances.hasPartner}<br/>
                    &emsp;Estimated Annual Income: {expandedApplication.application.personalFinances.annualIncome}<br/><br/>

                  Homeowner Status: <br/>
                    &emsp;Owns or Repaying their Home: {expandedApplication.application.homeownerStatus.ownHome}<br/>
                    &emsp;Protected Persons staying in the Home: {expandedApplication.application.homeownerStatus.protectedPersonAtHome}<br/>
                    &emsp;Net Market Value: ${expandedApplication.application.homeownerStatus.netMarketValue}<br/>
                    &emsp;Intention to Sell for Care: {expandedApplication.application.homeownerStatus.intentionToSell}<br/><br/>

                  Financial Assets: <br/>
                    &emsp;Value of financial Assets: ${expandedApplication.application.financialAssets.value}<br/><br/>

                  Other Assets: <br/>
                    &emsp;Value of financial Assets: ${expandedApplication.application.otherAssets.value}<br/><br/>

                  Debt: <br/>
                    &emsp;Value of financial Assets: ${expandedApplication.application.debt.value}<br/><br/>
                </Typography>
              )
            )
          }
        </Box>
      </div>
    </>
  );
}

export default React.memo(ReviewQuestionnaire);