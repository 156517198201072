import { Config } from "../config/config";

const Conf = new Config(process.env.REACT_APP_ENVIRONMENT_NAME ? process.env.REACT_APP_ENVIRONMENT_NAME : 'staging');

export async function postUpdateDap(token: string, reqBody: any) {
  const accessToken = token;
  console.log(`ReqBody:`);
  console.log(reqBody);
  const reqOptions = {
    method: "POST",
    headers: { 
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(reqBody),
  }
  const retValue: any = await fetch(`${Conf.vars.CARE360_WEBSITE_URL}/api/admin/jobs/updateDap`, reqOptions).then(async (response) => {
    const jsonRes = await response.json();
    return jsonRes;
  }).catch((error) => {
    console.log(`got error ${error}`);
    return undefined;
  });
  return retValue;
}