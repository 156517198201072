import { IInvoice } from "../interfaces/invoice";
import { Config } from "../config/config";
import {
	ICarepayApplication,
	ICarepayApplicationPayload,
} from "../interfaces/carepayApplication";

const Conf = new Config(
	process.env.REACT_APP_ENVIRONMENT_NAME
		? process.env.REACT_APP_ENVIRONMENT_NAME
		: "staging"
);

export async function postInvoice(token: string, reqBody: IInvoice) {
	const accessToken = token;
	console.log(`ReqBody:`);
	console.log(reqBody);
	const reqOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify(reqBody),
	};
	const retValue: any = await fetch(
		`${Conf.vars.REACT_APP_CAREPAY_API_URL}/api/invoice`,
		reqOptions
	)
		.then(async (response) => {
			const jsonRes = await response.json();
			return jsonRes;
		})
		.catch((error) => {
			console.log(`got error ${error}`);
			return undefined;
		});
	return retValue;
}

export async function getContacts(token: string) {
	const accessToken = token;

	const reqOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};
	const retValue: any = await fetch(
		`${Conf.vars.REACT_APP_CAREPAY_API_URL}/api/contacts`,
		reqOptions
	)
		.then(async (response) => {
			const jsonRes = await response.json();
			return jsonRes;
		})
		.catch((error) => {
			console.log(`got error ${error}`);
			return undefined;
		});
	return retValue;
}

export async function getRules(token: string, trustRef: string) {
	const accessToken = token;

	const reqOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};
	const retValue: any = await fetch(
		`${Conf.vars.REACT_APP_CAREPAY_API_URL}/api/carepay/admin/rules/${trustRef}`,
		reqOptions
	)
		.then(async (response) => {
			const jsonRes = await response.json();
			return jsonRes;
		})
		.catch((error) => {
			console.log(`got error ${error}`);
			return undefined;
		});
	return retValue;
}

export async function addContact(token: string, reqBody: any) {
	const accessToken = token;

	const reqOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify(reqBody),
	};
	const retValue: any = await fetch(
		`${Conf.vars.REACT_APP_CAREPAY_API_URL}/api/contact`,
		reqOptions
	)
		.then(async (response) => {
			const jsonRes = await response.json();
			return jsonRes;
		})
		.catch((error) => {
			console.log(`got error ${error}`);
			return undefined;
		});
	return retValue;
}

export async function uploadInvoicePdf(token: string, file: File | undefined) {
	if (file === undefined) {
		return { error: "A file us required" };
	}
	console.log(`Whats the file?`);
	console.log(file);
	const accessToken = token;

	const formData = new FormData();
	formData.append("fileToUpload", file);
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
		body: formData,
	};

	const retValue: any = await fetch(
		`${Conf.vars.REACT_APP_CAREPAY_API_URL}/api/invoice/uploadPdf`,
		requestOptions
	)
		.then(async (response) => {
			const jsonRes = await response.json();
			return jsonRes;
		})
		.catch((error) => {
			console.log(`got error ${error}`);
			return undefined;
		});
	return retValue;
}

export async function uploadCarepayApplication(
	token: string,
	applicationPayload: ICarepayApplicationPayload
) {
	const accessToken = token;

	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify(applicationPayload),
	};

	const retValue: any = await fetch(
		`${Conf.vars.REACT_APP_CAREPAY_API_URL}/api/carepay/application`,
		requestOptions
	)
		.then(async (response) => {
			const res = await response.json();
			return res;
		})
		.catch((error) => {
			console.log(`got error ${error}`);
			return undefined;
		});
	return retValue;
}

export async function getCarepayApplicationsByStatus(
	token: string,
	status: string
) {
	const accessToken = token;

	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const retValue: any = await fetch(
		`${Conf.vars.REACT_APP_CAREPAY_API_URL}/api/carepay/applications/${status}`,
		requestOptions
	)
		.then(async (response) => {
			const res = await response.json();
			return res;
		})
		.catch((error) => {
			console.log(`got error ${error}`);
			return undefined;
		});
	return retValue;
}

export async function getUserDetailsForEmail(token: string, email: string) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const retValue: any = await fetch(
		`${Conf.vars.REACT_APP_CAREPAY_API_URL}/api/account/getDetails/${email}`,
		requestOptions
	)
		.then(async (response) => {
			const res = await response.json();
			return res;
		})
		.catch((error) => {
			console.log(`got error ${error}`);
			return undefined;
		});
	return retValue;
}

export async function createCarepayAccount(token: string, username: string) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({ userId: username }),
	};

	const retValue: any = await fetch(
		`${Conf.vars.REACT_APP_CAREPAY_API_URL}/api/account`,
		requestOptions
	)
		.then(async (response) => {
			const res = await response.json();
			return res;
		})
		.catch((error) => {
			console.log(`got error ${error}`);
			return undefined;
		});
	return retValue;
}
