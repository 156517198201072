import axios from "axios";

interface FetcherArgs {
	url: string;
	token?: string;
}

export const fetcher = async ({ url, token }: FetcherArgs) => {
	try {
		const response = await axios.get(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};
