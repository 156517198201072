import React, { useContext } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { addContact } from '../services/carepayService';

import '../App.css';

export const AddContact = () => {
  const { token } = useContext(AuthContext);
  const [ contactName, setContactName ] = React.useState('');
  const [ contactAbn, setContactAbn ] = React.useState('');
  const [ contactBankBsb, setContactBankBsb ] = React.useState('');
  const [ contactBankAccountNumber, setContactBankAccountNumber ] = React.useState('');
  const [ responseMessage, setResponseMessage ] = React.useState('');

  const handleChangeContactName = async (event: any) => {
    setContactName(event.target.value);
  }
  const handleChangeContactAbn = async (event: any) => {
    setContactAbn(event.target.value);
  }
  const handleChangeContactBankAccountBsb = async (event: any) => {
    setContactBankBsb(event.target.value);
  }
  const handleChangeContactBankAccountNumber = async (event: any) => {
    setContactBankAccountNumber(event.target.value);
  }

  const submitContact = async () => {
    const contact = {
      contactName,
      contactAbn,
      contactBankAccountDetails: `${contactBankBsb} ${contactBankAccountNumber}`
    }
    const addContactResponse = await addContact(token, contact);
    if (addContactResponse.status === "Success") {
      setResponseMessage("Success");
    } else {
      setResponseMessage(addContactResponse.message);
    }
    console.log(`What is in ${JSON.stringify(addContactResponse)}`)
  }

  return (
    <>
      <Box
        sx={{bgcolor:'#fff'}}
      >
        <FormControl fullWidth>
          <div>
            Adding a new Contact
          </div>
          <TextField
            id="add-contact-name"
            value={contactName}
            label="Contact Name"
            variant="standard"
            onChange={handleChangeContactName}
            >
          </TextField>
          <br/>

          <TextField
            id="add-contact-anb"
            value={contactAbn}
            label="Contact Abn"
            variant="standard"
            onChange={handleChangeContactAbn}
            >
          </TextField>
          <br/>

          <TextField
            id="add-contact-bank-bsb"
            value={contactBankBsb}
            label="Contact Bank BSB"
            variant="standard"
            onChange={handleChangeContactBankAccountBsb}
            >
          </TextField>
          <br/>

          <TextField
            id="add-contact-bank-account-number"
            value={contactBankAccountNumber}
            label="Contact Bank Account Number"
            variant="standard"
            onChange={handleChangeContactBankAccountNumber}
            >
          </TextField>
          <br/>

          <Button
            variant="contained"
            onClick={submitContact}
            style={{display:'flex',alignItems:'center',justifyContent:'center', alignSelf:'center', backgroundColor:'#AB1287'}}
          >
            Add Contact
          </Button>
          <br/>

          {
            (
              (responseMessage !== "") && (
                <div>
                  {responseMessage}
                </div>
              )
            )
          }
        </FormControl>
      </Box>
    </>
  )
};
