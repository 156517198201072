import React, { useContext, ChangeEvent, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Button, FormControl, TextField, Box } from '@mui/material';
import { postUpdateDap } from '../services/care360WebsiteService';

const UpdateDAP = () => {
  const { token } = useContext(AuthContext);
  const [ mpir, setMpir ] = useState('');

  const handleChangeMpir = (event: any) => {
    setMpir(event.target.value);
  }

  const pushUpdateDap = async () => {
    // Yeet to Server
    const reqBody = {
      mpir
    }

    postUpdateDap(token, reqBody).then(
      (res: any) => {console.log(JSON.stringify(res))}
    );
  }

  return (
    <>
      <Box>
        <FormControl fullWidth>
          <TextField
            id="admin-update-dap-mpir"
            value={mpir}
            label="What is the MPIR?"
            variant="standard"
            onChange={handleChangeMpir}
            style={{marginTop: '10px'}}
            >
          </TextField>
        </FormControl>
        <Button
            variant="contained"
            onClick={pushUpdateDap}
            style={{marginTop: '25px'}}
          >
            Update DAPs for this MPIR
        </Button>
      </Box>
    </>
  );
}

export default React.memo(UpdateDAP);