import { STAGING_CONFIG } from './stagingConstants';
import { PRODUCTION_CONFIG  } from './productionConstants';

export interface IConfig {
  REGION: string
  IDENTITY_PROVIDER_CLIENT_ID: string
  IDENTITY_PROVIDER_OAUTH_ENDPOINT: string
  IDENTITY_PROVIDER_REDIRECT_URI: string
  IDENTITY_PROVIDER_DEFAULT_SCOPES: string[]

  REACT_APP_CAREPAY_API_URL: string
  CARE360_WEBSITE_URL: string

  BASE_URL: string
}

export class Config {
  environment: string
  vars: IConfig

  constructor(environment: string) {
    this.environment = environment;
    if (environment === "staging") {
      this.vars = STAGING_CONFIG;
    } else if (environment === "production") {
      // Default to staging config so we cannot hurt PROD resources
      this.vars = PRODUCTION_CONFIG;
    } else {
      this.vars = STAGING_CONFIG;
    }
  }
}