interface ModalProps {
	isOpen: boolean;
	message: string;
	onAbort: () => void;
	onConfirm: () => void;
}

const ConfirmationModal = ({
	isOpen,
	message,
	onAbort,
	onConfirm,
}: ModalProps) => {
	if (!isOpen) {
		return null;
	}

	return (
		<>
			<div className="absolute top-0 left-0 w-full h-full bg-gray-600 bg-opacity-50 flex items-center justify-center">
				<div className="bg-slate-100 w-3/4 max-w-lg border rounded-md p-5">
					<div className="text-center pt-4">{message}</div>
					<div className="flex justify-center py-5 gap-2">
						<button
							type="button"
							className="bg-red-600 p-2 rounded-md text-white w-24 hover:bg-red-500"
							onClick={onAbort}
						>
							Abort
						</button>
						<button
							type="button"
							className="bg-emerald-600 p-2 rounded-md text-white w-24 hover:bg-emerald-500"
							onClick={onConfirm}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfirmationModal;
