import { IConfig } from './config';

export const STAGING_CONFIG: IConfig = {
  REGION: "ap-southeast-2",
  IDENTITY_PROVIDER_CLIENT_ID: "pe35forhsc72l0ib7mn2h5qlt",
  IDENTITY_PROVIDER_OAUTH_ENDPOINT: 'https://care360-admin.auth.ap-southeast-2.amazoncognito.com/oauth2',
  IDENTITY_PROVIDER_REDIRECT_URI: 'http://localhost:3000/',
  IDENTITY_PROVIDER_DEFAULT_SCOPES: ['openid', 'email', 'profile'],

  REACT_APP_CAREPAY_API_URL: "http://localhost:3095",
  CARE360_WEBSITE_URL: "http://localhost:8080",
  BASE_URL: "http://localhost:3000"
};
