export interface JobRowOnDDB {
	completed_timestamp?: string;
	uuid: string;
	jobType: string;
	jobDescription: string;
	initiated_timestamp: string;
	status: string;
	message?: string;
}

export interface JobStatusResponse {
	ok: boolean;
	data: {
		Items: JobRowOnDDB[];
		Count: number;
		ScannedCount: number;
	};
}

export interface JobQuery {
	jobType: JobTypes;
	status: StatusTypes;
}

export enum StatusTypes {
	RUNNING = "Running",
	COMPLETED = "Completed",
	ERROR = "Error",
}

export enum JobTypes {
	SYNC_HOME_DATA_FROM_SF_TO_ES = "1",
	UPDATE_DAPS = "2",
	SYNC_HOME_DATA_FROM_MAC_TO_SF = "3",
}
