import React, { useContext, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import '../App.css';
import { ICarepayApplication, ICarepayApplicationPayload } from '../interfaces/carepayApplication';
import { uploadCarepayApplication } from '../services/carepayService';

const CarePayQuestionnaire = () => {
  const { token } = useContext(AuthContext);

  /*
   * Begin Personal Information section
   *
   */
  const [personalInfoSelf, setPersonalInfoSelf] = React.useState('');
  const [personalInfoName, setPersonalInfoName] = React.useState('');
  const [personalInfoRelationship, setPersonalInfoRelationship] = React.useState('');
  const [personalInfoEpoa, setPersonalInfoEpoa] = React.useState('');
  const [personalInfoEmail, setPersonalInfoEmail] = React.useState('');

  const handleChangePersonalInfoSelf = (event: any) => {
    setPersonalInfoSelf(event.target.value);
  }
  const handleChangePersonalInfoName = (event: any) => {
    setPersonalInfoName(event.target.value);
  }
  const handleChangePersonalInfoRelationship = (event: any) => {
    setPersonalInfoRelationship(event.target.value);
  }
  const handleChangePersonalInfoEpoa = (event: any) => {
    setPersonalInfoEpoa(event.target.value);
  }
  const handleChangePersonalInfoEmail = (event: any) => {
    setPersonalInfoEmail(event.target.value);
  }

  const PersonalInfo = (
    <>
      <Toolbar
        style={{'backgroundColor':'#CCE9F3', 'marginTop': '50px'}}
      >
        <Typography variant="h6" component="h1">
          Personal Information
        </Typography>
      </Toolbar>
      <FormControl fullWidth style={{'paddingTop': '30px'}}>
        <InputLabel
          id="carepay-questionnaire-personal-info-who-label"
          style={{'paddingTop': '20px'}}
        >
          Are you filling this form out for yourself, or someone else?
        </InputLabel>
        <Select
          labelId="carepay-questionnaire-personal-info-who-label"
          id="carepay-questionnaire-personal-info-who-select"
          value={personalInfoSelf}
          onChange={handleChangePersonalInfoSelf}
        >
            <MenuItem value={"SELF"}>Self</MenuItem>
            <MenuItem value={"OTHER"}>Someone Else</MenuItem>
        </Select>
        <TextField
              id="carepay-questionnaire-personal-info-name"
              value={personalInfoName}
              label="What is your name?"
              variant="outlined"
              onChange={handleChangePersonalInfoName}
              style={{'marginTop':'10px'}}
              fullWidth
            />
      </FormControl>
      {
        (personalInfoSelf === "OTHER") && (
          <>
            <TextField
              id="carepay-questionnaire-personal-info-relationship"
              value={personalInfoRelationship}
              label="What is your relationship to the applicant?"
              variant="outlined"
              onChange={handleChangePersonalInfoRelationship}
              style={{'marginTop':'10px'}}
              fullWidth
            />
            <FormControl fullWidth style={{'paddingTop': '30px'}}>
              <InputLabel
                id="carepay-questionnaire-personal-info-epoa-label"
                style={{'paddingTop': '20px'}}
              >
                Do you have Enduring Power of Attorney / Authority to Complete this form on the Applicants behalf?
              </InputLabel>
              <Select
                labelId="carepay-questionnaire-personal-info-epoa-label"
                id="carepay-questionnaire-personal-info-epoa-select"
                value={personalInfoEpoa}
                onChange={handleChangePersonalInfoEpoa}
              >
                  <MenuItem value={"YES"}>Yes</MenuItem>
                  <MenuItem value={"NO"}>No</MenuItem>
              </Select>
            </FormControl>
          </>
        )
      }
      <FormControl fullWidth style={{'paddingTop': '30px'}}>
        <TextField
          id="carepay-questionnaire-personal-info-email"
          value={personalInfoEmail}
          label="What is your email address?"
          variant="outlined"
          onChange={handleChangePersonalInfoEmail}
          style={{'marginTop':'10px'}}
          fullWidth
        />
      </FormControl>
    </>
  )

  /*
   *  Begin ACAT section
   *
   */
  const [acatAssessmentReceived, setAcatAssessmentReceived] = React.useState('');
  const [acatAssessmentCareLevel, setAcatAssessmentCareLevel] = React.useState<string[]>([]);
  const [acatAssessmentDate, setAcatAssessmentDate] = React.useState('');
  const [acatAssessmentNeedsChanged, setAcatAssessmentNeedsChanged] = React.useState('');

  const handleChangeAcatAssessmentReceived = (event: any) => {
    setAcatAssessmentReceived(event.target.value);
    // If assessment is not completed, reset other values
    if (event.target.value === "NO") {
      setAcatAssessmentCareLevel([]);
      setAcatAssessmentDate('');
      setAcatAssessmentNeedsChanged('');
    }
  }
  const handleChangeAcatAssessmentCareLevel = (event: any) => {
    setAcatAssessmentCareLevel(event.target.value);
  }
  const handleChangeAssessmentDate = (value: any) => {
    setAcatAssessmentDate(value);
  }
  const handleChangeAcatAssessmentNeedsChanged = (event: any) => {
    setAcatAssessmentNeedsChanged(event.target.value);
  }

  const AcatAssessment = (
    <>
      <Toolbar
        style={{'backgroundColor':'#CCE9F3', 'marginTop': '50px'}}
      >
        <Typography variant="h6" component="h1">
          ACAT Assessment
        </Typography>
      </Toolbar>
      <FormControl fullWidth style={{'paddingTop': '30px'}}>
        <InputLabel
          id="carepay-questionnaire-acat-assessment-label"
          style={{'paddingTop': '20px'}}
        >
          {
            (personalInfoSelf === "OTHER" ? "Has your loved one received an ACAT assessment?" : "Have you received an ACAT assessment?")
          }
        </InputLabel>
        <Select
          labelId="carepay-questionnaire-acat-assessment-label"
          id="carepay-questionnaire-acat-assessment-select"
          value={acatAssessmentReceived}
          onChange={handleChangeAcatAssessmentReceived}
        >
            <MenuItem value={"YES"}>Yes</MenuItem>
            <MenuItem value={"NO"}>No</MenuItem>
        </Select>
      </FormControl>
      <br/>
      {
        (acatAssessmentReceived === "YES") && (
          <FormControl fullWidth style={{'marginTop':'10px'}}>
            <InputLabel
              id="carepay-questionnaire-acat-assessment-care-level-label"
            >
              What was your assessed Care Approval Level?
            </InputLabel>
            <Select
              labelId="carepay-questionnaire-acat-assessment-care-level-label"
              id="carepay-questionnaire-acat-assessment-care-level-select"
              value={acatAssessmentCareLevel}
              onChange={handleChangeAcatAssessmentCareLevel}
              style={{'marginTop':'10px'}}
              multiple
            >
              <MenuItem value={"HIGH_PERMANENT"}>High Care Permanent</MenuItem>
              <MenuItem value={"LOW_PERMANENT"}>Low Care Permanent</MenuItem>
              <MenuItem value={"HIGH_RESPITE"}>High Care Respite</MenuItem>
              <MenuItem value={"LOW_RESPITE"}>Low Care Respite</MenuItem>
            </Select>
          </FormControl>
        )
      }
      {
        (acatAssessmentCareLevel.includes("LOW_PERMANENT") || acatAssessmentCareLevel.includes("LOW_RESPITE")) && (
          <FormControl fullWidth style={{'marginTop':'10px'}}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                value={acatAssessmentDate}
                label="Assessment Date"
                inputFormat='YYYY-MM-DD'
                onChange={handleChangeAssessmentDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <br/>
            <InputLabel
              id="carepay-questionnaire-acat-assessment-care-needs-changed-label"
              style={{'marginTop':'68px'}}
            >
              Have your Care needs changed since Approval?
            </InputLabel>
            <Select
              labelId="carepay-questionnaire-acat-assessment-care-needs-changed-label"
              id="carepay-questionnaire-acat-assessment-care-needs-changed-select"
              value={acatAssessmentNeedsChanged}
              onChange={handleChangeAcatAssessmentNeedsChanged}

            >
              <MenuItem value={"YES"}> Yes</MenuItem>
              <MenuItem value={"NO"}>No</MenuItem>
            </Select>
          </FormControl>
        )
      }
      {
        (acatAssessmentReceived === "NO") && (
          <div>
            If you are awaiting approval, you can complete this form. If not, go to <Link href="https://www.myagedcare.gov.au/assessment">MyAgedCare</Link> to begin an ACAT approval.
          </div>
        )
      }
    </>
  );

  /*
   *  Begin Income Assessment section
   *
   */
  const [incomeAssetFormsAssessmentReceived, setIncomeAssetFormsAssessmentReceived] = React.useState('');
  const [incomeAssetFormsCompleted, setIncomeAssetFormsCompleted] = React.useState('');
  const [incomeAssetFormsSubmitted, setIncomeAssetFormsSubmitted] = React.useState('');
  const [incomeAssetFormResult, setIncomeAssetFormResult] = React.useState('');
  const [incomeAssetFormAuthorisedRepresentativeNominated, setIncomeAssetFormAuthorisedRepresentativeNominated] = React.useState('');
  
  const [incomeAssetFormRepresentativeName, setIncomeAssetFormRepresentativeName] = React.useState('');
  const [incomeAssetFormRepresentativePhone, setIncomeAssetFormRepresentativePhone] = React.useState('');
  const [incomeAssetFormRepresentativeEmail, setIncomeAssetFormRepresentativeEmail] = React.useState('');
  const [incomeAssetFormRepresentativeRelationship, setIncomeAssetFormRepresentativeRelationship] = React.useState('');

  const handleChangeIncomeAssetFormsAssessmentReceived = (event: any) => {
    setIncomeAssetFormsAssessmentReceived(event.target.value);
  }
  const handleChangeIncomeAssetFormsCompleted = (event: any) => {
    setIncomeAssetFormsCompleted(event.target.value);
  }
  const handleChangeIncomeAssetFormsSubmitted = (value: any) => {
    setIncomeAssetFormsSubmitted(value);
  }
  const handleChangeIncomeAssetFormResult = (event: any) => {
    setIncomeAssetFormResult(event.target.value);
  }
  const handleChangeIncomeAssetFormAuthorisedRepresentativeNominated = (event: any) => {
    setIncomeAssetFormAuthorisedRepresentativeNominated(event.target.value);
  }

  const handleChangeIncomeAssetFormRepresentativeName = (event: any) => {
    setIncomeAssetFormRepresentativeName(event.target.value);
  }
  const handleChangeIncomeAssetFormRepresentativePhone = (event: any) => {
    setIncomeAssetFormRepresentativePhone(event.target.value);
  }
  const handleChangeIncomeAssetFormRepresentativeEmail = (event: any) => {
    setIncomeAssetFormRepresentativeEmail(event.target.value);
  }
  const handleChangeIncomeAssetFormRepresentativeRelationship = (event: any) => {
    setIncomeAssetFormRepresentativeRelationship(event.target.value);
  }

  const IncomeAssessment = (
    <>
      <Toolbar
        style={{'backgroundColor':'#CCE9F3', 'marginTop': '50px'}}
      >
        <Typography variant="h6" component="h1">
          Income Assessment
        </Typography>
      </Toolbar>
      <FormControl fullWidth style={{'paddingTop': '30px'}}>
        <InputLabel
          id="carepay-questionnaire-income-assessment-label"
          style={{'paddingTop': '20px'}}
        >
          Have you received your Department of Human Services income assessment letter?
        </InputLabel>
        <Select
          labelId="carepay-questionnaire-income-assessment-label"
          id="carepay-questionnaire-income-assessment-select"
          value={incomeAssetFormsAssessmentReceived}
          onChange={handleChangeIncomeAssetFormsAssessmentReceived}
        >
            <MenuItem value={"YES"}>Yes</MenuItem>
            <MenuItem value={"NO"}>No</MenuItem>
        </Select>
      </FormControl>
      {
        (incomeAssetFormsAssessmentReceived === "NO") && (
          <FormControl fullWidth style={{'paddingTop': '30px'}}>
            <InputLabel
              id="carepay-questionnaire-income-assessment-label"
              style={{'paddingTop': '20px'}}
            >
              Have you/you're loved one completed income and assets forms <i>SA457</i> or <i>SA486</i>?
            </InputLabel>
            <Select
              labelId="carepay-questionnaire-income-assessment-label"
              id="carepay-questionnaire-income-assessment-select"
              value={incomeAssetFormsCompleted}
              onChange={handleChangeIncomeAssetFormsCompleted}
            >
                <MenuItem value={"YES"}>Yes</MenuItem>
                <MenuItem value={"NO"}>No</MenuItem>
            </Select>
          </FormControl>
        )
      }
      {
        (incomeAssetFormsCompleted === "NO") && (
          <div>
            We are unable to complete your application until you complete forms <Link href="https://www.servicesaustralia.gov.au/sa486">SA486</Link> and <Link href="https://www.servicesaustralia.gov.au/sa457">SA457</Link>. Please return here after completing these forms.
          </div>
        )
      }
      {
        (incomeAssetFormsCompleted === "YES") && (
          <FormControl fullWidth style={{'marginTop':'10px'}}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                value={incomeAssetFormsSubmitted}
                label="Assessment Date"
                inputFormat='YYYY-MM-DD'
                onChange={handleChangeIncomeAssetFormsSubmitted}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        )
      }
      {
        (incomeAssetFormsAssessmentReceived === "YES") && (
          <TextField
            id="carepay-questionnaire-income-assessment-response-information"
            value={incomeAssetFormResult}
            label="What was the result of your Assessment?"
            variant="outlined"
            onChange={handleChangeIncomeAssetFormResult}
            style={{'marginTop':'10px'}}
            fullWidth
            >
          </TextField>  
        )
      }
      <FormControl fullWidth style={{'paddingTop': '30px'}}>
        <InputLabel
          id="carepay-questionnaire-income-assessment-nominated-representative-label"
          style={{'paddingTop': '20px'}}
        >
          Have you nominated an organisation or individual as your authorised representative for Centrelink Matters?
        </InputLabel>
        <Select
          labelId="carepay-questionnaire-income-assessment-nominated-representative-label"
          id="carepay-questionnaire-income-assessment-nominated-representative-select"
          value={incomeAssetFormAuthorisedRepresentativeNominated}
          onChange={handleChangeIncomeAssetFormAuthorisedRepresentativeNominated}
        >
            <MenuItem value={"YES"}>Yes</MenuItem>
            <MenuItem value={"NO"}>No</MenuItem>
        </Select>
      </FormControl>
      {
        (incomeAssetFormAuthorisedRepresentativeNominated === "YES") && (
          <>
            <TextField
              id="carepay-questionnaire-income-assessment-nominated-representative-contact"
              value={incomeAssetFormRepresentativeName}
              label="What is the name of your Authorised Representative?"
              variant="outlined"
              onChange={handleChangeIncomeAssetFormRepresentativeName}
              style={{'marginTop':'10px'}}
              fullWidth
            />
            <TextField
              id="carepay-questionnaire-income-assessment-nominated-representative-contact"
              value={incomeAssetFormRepresentativePhone}
              label="What is the phone number of your Authorised Representative?"
              variant="outlined"
              onChange={handleChangeIncomeAssetFormRepresentativePhone}
              style={{'marginTop':'10px'}}
              fullWidth
            />
            <TextField
              id="carepay-questionnaire-income-assessment-nominated-representative-contact"
              value={incomeAssetFormRepresentativeEmail}
              label="What is the email address of your Authorised Representative?"
              variant="outlined"
              onChange={handleChangeIncomeAssetFormRepresentativeEmail}
              style={{'marginTop':'10px'}}
              fullWidth
            />
            <TextField
              id="carepay-questionnaire-income-assessment-nominated-representative-contact"
              value={incomeAssetFormRepresentativeRelationship}
              label="What is your relationship to your Authorised Representative?"
              variant="outlined"
              onChange={handleChangeIncomeAssetFormRepresentativeRelationship}
              style={{'marginTop':'10px'}}
              fullWidth
            />
          </>
        )
      }
    </>
  );

  /*
   *  Begin Personal Finances Assessment section
   *
   */
  const [personalFinancesHasPartner, setPersonalFinancesHasPartner] = React.useState('');
  const [personalFinancesAnnualIncome, setPersonalFinancesAnnualIncome] = React.useState('');

  const handleChangePersonalFinancesHasPartner = (event: any) => {
    setPersonalFinancesHasPartner(event.target.value);
  }
  const handleChangePersonalFinancesAnnualIncome = (event: any) => {
    setPersonalFinancesAnnualIncome(event.target.value);
  }

  const PersonalFinances = (
    <>
      <Toolbar
        style={{'backgroundColor':'#CCE9F3', 'marginTop': '50px'}}
      >
        <Typography variant="h6" component="h1">
          Personal Finances
        </Typography>
      </Toolbar>
      <FormControl fullWidth style={{'paddingTop': '30px'}}>
        <InputLabel
          id="carepay-questionnaire-personal-finances-has-partner-label"
          style={{'paddingTop': '20px'}}
        >
          Do you have a Partner? (Married or De-Facto)
        </InputLabel>
        <Select
          labelId="carepay-questionnaire-personal-finances-has-partner-label"
          id="carepay-questionnaire-personal-finances-has-partner-select"
          value={personalFinancesHasPartner}
          onChange={handleChangePersonalFinancesHasPartner}
        >
            <MenuItem value={"YES"}>Yes</MenuItem>
            <MenuItem value={"NO"}>No</MenuItem>
        </Select>
        <div>
          <TextField
            id="carepay-questionnaire-income-assessment-nominated-representative-contact"
            value={personalFinancesAnnualIncome}
            label={
              (personalFinancesHasPartner === "YES") ? "What is your combined estimated annual income?" : "What is your estimated annual income"
            }
            variant="outlined"
            onChange={handleChangePersonalFinancesAnnualIncome}
            style={{'width': '95%', 'marginTop':'10px'}}
            >
          </TextField>
          <Tooltip
            title={
              <>
                <div>
                  This includes the following income sources:
                </div>
                <List>
                  <ListItem>
                    Income payment from the Aus Govt.
                  </ListItem>
                  <ListItem>
                    Superannuation annuities and allocated pensions
                  </ListItem>
                  <ListItem>
                    Net income from business, including farms
                  </ListItem>
                  <ListItem>
                    Overseas pensions
                  </ListItem>
                  <ListItem>
                    Family trust distributions
                  </ListItem>
                  <ListItem>
                    Net income from rental property
                  </ListItem>
                  <ListItem>
                    Dividends from private companies or shares
                  </ListItem>
                </List>
              </>
            }
          >
            <HelpOutlineIcon 
              fontSize='large' 
              style={{'marginTop':'20px'}}
            />
          </Tooltip>
        </div>        
      </FormControl>
    </>
  );

  /*
   *  Begin Homeowner Status section
   *
   */
  const [homeownerStatusOwnHome, setHomeownerStatusOwnHome] = React.useState('');
  const [homeownerStatusProtectedPersonAtHome, setHomeownerStatusProtectedPersonAtHome] = React.useState('');
  const [homeownerStatusNetMarketValue, setHomeownerStatusNetMarketValue] = React.useState('');
  const [homeownerStatusIntentionToSell, setHomeownerStatusIntentionToSell] = React.useState('');

  const handleChangeHomeownerStatusOwnHome = (event: any) => {
    setHomeownerStatusOwnHome(event.target.value);
  };
  const handleChangeHomeownerStatusProtectedPersonAtHome = (event: any) => {
    setHomeownerStatusProtectedPersonAtHome(event.target.value);
  };
  const handleChangeHomeownerStatusNetMarketValue = (event: any) => {
    setHomeownerStatusNetMarketValue(event.target.value);
  };
  const handleChangeHomeownerStatusIntentionToSell = (event: any) => {
    setHomeownerStatusIntentionToSell(event.target.value);
  };

  const HomeownerStatus = (
    <>
      <Toolbar
        style={{'backgroundColor':'#CCE9F3', 'marginTop': '50px'}}
      >
        <Typography variant="h6" component="h1">
          Homeowner Status
        </Typography>
      </Toolbar>
      <FormControl fullWidth style={{'paddingTop': '30px'}}>
        <InputLabel
          id="carepay-questionnaire-homeowner-status-own-home-label"
          style={{'paddingTop': '20px'}}
        >
          Do you own, or are you paying off, the home you live in?
        </InputLabel>
        <Select
          labelId="carepay-questionnaire-homeowner-status-own-home-label"
          id="carepay-questionnaire-homeowner-status-own-home-select"
          value={homeownerStatusOwnHome}
          onChange={handleChangeHomeownerStatusOwnHome}
        >
            <MenuItem value={"YES"}>Yes</MenuItem>
            <MenuItem value={"NO"}>No</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth style={{'marginTop': '20px'}}>
        <div>
          <InputLabel
            id="carepay-questionnaire-homeowner-status-protected-person-label"
          >
            Will a protected person be living in the home?
          </InputLabel>
          <Select
            labelId="carepay-questionnaire-homeowner-status-protected-person-label"
            id="carepay-questionnaire-homeowner-status-protected-person-select"
            value={homeownerStatusProtectedPersonAtHome}
            onChange={handleChangeHomeownerStatusProtectedPersonAtHome}
            style={{'width':'95%'}}
          >
              <MenuItem value={"YES"}>Yes</MenuItem>
              <MenuItem value={"NO"}>No</MenuItem>
          </Select>
          <Tooltip
            title={
              <>
                <div>
                  Your home will be considered as an asset unless it is occupied by a Protected Person. A protected person is one of the following;
                </div>
                <List>
                  <ListItem>
                    Your partner/dependent child/carer who has lived with you in your home for the past two years and is eligible for an income support payment
                  </ListItem>
                  <ListItem>
                    A close relation (eg sibling, parent, child, grandchild) who has lived with you in the home for the past 5 years and is eligible for an income support payment.
                  </ListItem>
                </List>
              </>
            }
          >
            <HelpOutlineIcon 
              fontSize='large' 
              style={{'marginTop':'15px'}}
            />
          </Tooltip>
        </div>
      </FormControl>
      <FormControl fullWidth style={{'marginTop': '20px'}}>
        <TextField
          id="carepay-questionnaire-income-assessment-nominated-representative-contact"
          value={homeownerStatusNetMarketValue}
          label="What is the net market value of your home?"
          variant="outlined"
          onChange={handleChangeHomeownerStatusNetMarketValue}
        />
      </FormControl>
      <FormControl fullWidth style={{'marginTop': '20px'}}>
        <InputLabel
          id="carepay-questionnaire-homeowner-status-intent-to-sell-label"
        >
          Do you intend to <i>sell</i> your home to fund your care and accommodation fees?
        </InputLabel>
        <Select
          labelId="carepay-questionnaire-homeowner-status-intent-to-sell-label"
          id="carepay-questionnaire-homeowner-status-intent-to-sell-select"
          value={homeownerStatusIntentionToSell}
          onChange={handleChangeHomeownerStatusIntentionToSell}
          style={{marginTop:'20px'}}
        >
          <MenuItem value={"YES"}>Yes</MenuItem>
          <MenuItem value={"NO"}>No</MenuItem>
          <MenuItem value={"UNSURE"}>Unsure</MenuItem>
        </Select>
      </FormControl>
    </>
  )

  /*
   *  Begin Financial Assets section
   *
   */
  const [financialAssetsTotalValue, setFinancialAssetsTotalValue] = React.useState('');

  const handleChangeFinancialAssetsTotalValue = (event: any) => {
    setFinancialAssetsTotalValue(event.target.value);
  };

  const FinancialAssets = (
    <>
      <Toolbar 
        style={{'backgroundColor':'#CCE9F3', 'marginTop': '50px'}}
      >
        <Typography variant="h6" component="h1">
          Financial Assets
        </Typography>
      </Toolbar>
      <FormControl fullWidth style={{'marginTop': '20px'}}>
        <div>
          <TextField
            id="carepay-questionnaire-finanial-assets-total-value"
            value={financialAssetsTotalValue}
            label="What is the total value of your financial assets?"
            variant="outlined"
            onChange={handleChangeFinancialAssetsTotalValue}
            style={{'width':'95%'}}
          />
          <Tooltip
            title={
              <>
                <div>
                  Financial Assets Include:
                </div>
                <List>
                  <ListItem>
                    Bank, Building Society, and Credit Union Accounts
                  </ListItem>
                  <ListItem>
                    Term Deposits
                  </ListItem>
                  <ListItem>
                    Listed Shares and Securities
                  </ListItem>
                  <ListItem>
                    Shares in unlisted public companies
                  </ListItem>
                  <ListItem>
                    Friendly Society Bonds
                  </ListItem>
                  <ListItem>
                    Gifted Assets - If you have gifted amounts more than $10,000 in the last year and a total of $30,000 in the last five years, include the amount above.
                  </ListItem>
                </List>
              </>
            }
          >
            <HelpOutlineIcon 
              fontSize='large' 
              style={{'marginTop':'15px'}}
            />
          </Tooltip>
        </div>
      </FormControl>
    </>
  )

  /*
   *  Begin Other Assets section
   *
   */
  const [otherAssetsTotalValue, setOtherAssetsTotalValue] = React.useState('');

  const handleChangeOtherAssetsTotalValue = (event: any) => {
    setOtherAssetsTotalValue(event.target.value);
  }

  const OtherAssets = (
    <>
      <Toolbar
        style={{'backgroundColor':'#CCE9F3', 'marginTop': '50px'}}
      >
        <Typography variant="h6" component="h1">
          Other Assets
        </Typography>
      </Toolbar>
      <FormControl fullWidth style={{'marginTop': '20px'}}>
        <div>
          <TextField
            id="carepay-questionnaire-other-assets-total-value"
            value={otherAssetsTotalValue}
            label="What is the total value of your other assets?"
            variant="outlined"
            onChange={handleChangeOtherAssetsTotalValue}
            style={{'width':'95%'}}
          />
          <Tooltip
            title={
              <>
                <div>
                  Other Assets Include:
                </div>
                <List>
                  <ListItem>
                    Household Contents
                  </ListItem>
                  <ListItem>
                    Foreign Assets - including Investments, Business Interests, and Real Estate
                  </ListItem>
                  <ListItem>
                    Superannuation Balances
                  </ListItem>
                  <ListItem>
                    Investment Property
                  </ListItem>
                  <ListItem>
                    Refundable Accomodation Deposits
                  </ListItem>
                  <ListItem>
                    Special Collections e.g. Art, Antiques, Stamps
                  </ListItem>
                  <ListItem>
                    Private Trusts, Family Trusts, Private Companies
                  </ListItem>
                </List>
              </>
            }
          >
            <HelpOutlineIcon 
              fontSize='large' 
              style={{'marginTop':'15px'}}
            />
          </Tooltip>
        </div>
      </FormControl>
    </>
  )

    /*
   *  Begin Debts Assets section
   *
   */
    const [totalDebt, setTotalDebt] = React.useState('');

    const handleChangeTotalDebt = (event: any) => {
      setTotalDebt(event.target.value);
    }
  
    const Debts = (
      <>
        <Toolbar
          style={{'backgroundColor':'#CCE9F3', 'marginTop': '50px'}}
        >
          <Typography variant="h6" component="h1">
            Debts
          </Typography>
        </Toolbar>
        <FormControl fullWidth style={{'marginTop': '20px'}}>
          <div>
            <TextField
              id="carepay-questionnaire-other-assets-total-value"
              value={totalDebt}
              label="What is your total debt?"
              variant="outlined"
              onChange={handleChangeTotalDebt}
              style={{'width':'95%'}}
            />
            <Tooltip
              title={
                <>
                  <div>
                    Debt includes:
                  </div>
                  <List>
                    <ListItem>
                      Value of mortgage over the home (if any)
                    </ListItem>
                    <ListItem>
                      Value of personal loans (if any)
                    </ListItem>
                  </List>
                  <div><b>Debt does not include:</b></div>
                  <List>
                    <ListItem>
                      Credit Card Debt
                    </ListItem>
                    <ListItem>
                      A Loan taken out for the benefit of someone other than your partner. i.e. daughter who needed a new car or uni fees
                    </ListItem>
                  </List>
                </>
              }
            >
              <HelpOutlineIcon 
                fontSize='large' 
                style={{'marginTop':'15px'}}
                />
            </Tooltip>
          </div>
        </FormControl>
      </>
    )

  
  const handleApplyNow = () => {
    // Construct payload
    const application: ICarepayApplication = {
      personalInfo: {
        self: personalInfoSelf,
        name: personalInfoName,
        relationship: personalInfoRelationship,
        epoa: personalInfoEpoa,
        email: personalInfoEmail,
      },
      acatAssessment: {
        responseReceived: acatAssessmentReceived,
        careLevel: acatAssessmentCareLevel,
        date: acatAssessmentDate,
        needsChanges: acatAssessmentNeedsChanged
      },
      incomeAssessement: {
        assessmentReceived: incomeAssetFormsAssessmentReceived,
        formsCompleted: incomeAssetFormsCompleted,
        dateFormsSubmitted: incomeAssetFormsSubmitted,
        formResult: incomeAssetFormResult,
        authorisedRepresentative: incomeAssetFormAuthorisedRepresentativeNominated,
        authorisedRepresentativeDetails: {
          name: incomeAssetFormRepresentativeName,
          phone: incomeAssetFormRepresentativePhone,
          email: incomeAssetFormRepresentativeEmail,
          relationship: incomeAssetFormRepresentativeRelationship
        }
      },
      personalFinances: {
        hasPartner: personalFinancesHasPartner,
        annualIncome: personalFinancesAnnualIncome
      },
      homeownerStatus: {
        ownHome: homeownerStatusOwnHome,
        protectedPersonAtHome: homeownerStatusProtectedPersonAtHome,
        netMarketValue: homeownerStatusNetMarketValue,
        intentionToSell: homeownerStatusIntentionToSell
      },
      financialAssets: {
        value: financialAssetsTotalValue
      },
      otherAssets: {
        value: otherAssetsTotalValue,
      },
      debt: {
        value: totalDebt,
      }
    }
    
    const saveApplication: ICarepayApplicationPayload = {
      email: personalInfoEmail,
      applicationStatus: 'AWAITING_REVIEW',
      application
    }

    uploadCarepayApplication(token, saveApplication).then(
      (res)=> {
        console.log(`Got res ${JSON.stringify(res)}`)
        if (res.status) {
          setUploadStatus(res.status);
        }
        if (res.message) {
          setUploadMessage(res.message)
        }
      }
    )
  }

  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');

  return (
    <>
      <div style={{overflow: 'hidden'}}>
        <Box sx={{width: 20, height:1000}} style={{'float': 'left'}}/>
        <Box sx={{width: 900}}  style={{overflow: 'hidden'}} >
          {PersonalInfo}
          {AcatAssessment}
          {IncomeAssessment}
          {PersonalFinances}
          {HomeownerStatus}
          {FinancialAssets}
          {OtherAssets}
          {Debts}
          <Button
            style={{marginTop: '20px'}}
            variant="contained"
            onClick={handleApplyNow}
            >
            Apply Now
          </Button>
          {
            (uploadStatus !== '') && (
              <Typography>
                {uploadStatus}
              </Typography>
            )
          }
          {
            (uploadMessage !== '') && (
              <Typography>
                {uploadMessage}
              </Typography>
            )
          }
        </Box>
      </div>
      <div style={{height: '50px'}} />
    </>
  )
};

export default React.memo(CarePayQuestionnaire);
