import React, { useCallback, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import LoginLogout from "./loginLogout";
import "../App.css";
import { AuthContext } from "react-oauth2-code-pkce";
import { Config } from "../config/config";
import usePostUtility from "../utilities/usePostUtility";
import { classNames } from "../utilities/generalUtilities";
import {
	JobStatusResponse,
	StatusTypes,
	JobQuery,
	JobTypes,
} from "../interfaces/jobInterfaces";
import ConfirmationModal from "./ConfirmationModal";

type Action = () => void;

const Conf = new Config(
	process.env.REACT_APP_ENVIRONMENT_NAME
		? process.env.REACT_APP_ENVIRONMENT_NAME
		: "staging"
);

const SyncStatus = () => {
	const { token } = useContext(AuthContext);
	const [SfToEsImportJobExists, setSfToEsImportJobExists] = useState(false);
	const [
		myAgedCareToSalesforceJobRunning,
		setMyAgedCareToSalesforceJobRunning,
	] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentConfirmAction, setCurrentConfirmAction] =
		useState<null | Action>(null);

	const confirmImportFromSfToEs = () => {
		triggerImportFromSfToEs({
			token: token,
			body: {
				jobType: JobTypes.SYNC_HOME_DATA_FROM_SF_TO_ES,
			},
		});
		mutate();
	};

	const confirmSyncMyAgedCareData = () => {
		triggerSyncWithMyAgedCare({
			token: token,
			body: {
				jobType: JobTypes.SYNC_HOME_DATA_FROM_MAC_TO_SF,
			},
		});
		mutate();
	};

	const handleConfirm = () => {
		if (currentConfirmAction !== null) {
			currentConfirmAction();
		}
		setIsModalOpen(false);
	};

	const handleAbort = () => {
		setIsModalOpen(false);
	};

	const openModalWithAction = (action: Action) => {
		setCurrentConfirmAction(() => action);
		setIsModalOpen(true);
	};

	const [
		triggerImportFromSfToEs,
		{
			data: elasticSearchImportData,
			isLoading: elasticSearchImportIsLoading,
			error: elasticSearchImportError,
		},
	] = usePostUtility(
		`${Conf.vars.CARE360_WEBSITE_URL}/api/facilities/import`
	);

	const [
		triggerSyncWithMyAgedCare,
		{
			data: syncWithMyAgedCareData,
			isLoading: syncWithMyAgedCareIsLoading,
			error: syncWithMyAgedCareError,
		},
	] = usePostUtility(
		`${Conf.vars.CARE360_WEBSITE_URL}/api/find/jobs/myAgedCare/syncWithMyAgedCare`
	);

	const { data, error, mutate, isLoading, isValidating } =
		useSWR<JobStatusResponse>({
			url: `${Conf.vars.CARE360_WEBSITE_URL}/api/job-status`,
			token,
		});

	useEffect(() => {
		mutate();
	}, [
		elasticSearchImportData,
		elasticSearchImportIsLoading,
		elasticSearchImportError,
		syncWithMyAgedCareData,
		syncWithMyAgedCareIsLoading,
		syncWithMyAgedCareError,
		mutate,
	]);

	const checkJobTypeStatus = useCallback(
		(jobQuery: JobQuery) => {
			const isRunning = data?.data?.Items.find(
				(job) =>
					job.jobType === jobQuery.jobType &&
					job.status === jobQuery.status
			);
			return isRunning;
		},
		[data]
	);

	useEffect(() => {
		if (data) {
			const sfToEsImportIsRunning = checkJobTypeStatus({
				jobType: JobTypes.SYNC_HOME_DATA_FROM_SF_TO_ES,
				status: StatusTypes.RUNNING,
			});
			if (sfToEsImportIsRunning) {
				setSfToEsImportJobExists(true);
			} else {
				setSfToEsImportJobExists(false);
			}

			const syncMyAgedCareIsRunning = checkJobTypeStatus({
				jobType: JobTypes.SYNC_HOME_DATA_FROM_MAC_TO_SF,
				status: StatusTypes.RUNNING,
			});
			if (syncMyAgedCareIsRunning) {
				setMyAgedCareToSalesforceJobRunning(true);
			} else {
				setMyAgedCareToSalesforceJobRunning(false);
			}
		}
		return;
	}, [data, checkJobTypeStatus]);

	return (
		<>
			<LoginLogout />
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-base font-semibold leading-6 text-gray-900">
							Job Board
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							This page will allow you to sync Salesforce data
							with Elastic Search and show its status
						</p>
					</div>
				</div>
				{!data && !error && isLoading && <h1>Loading...</h1>}
				{!data && error && <h1>ERROR!!</h1>}
				{data && data.ok && (
					<div className="mt-8 flow-root">
						<div>
							<button
								type="button"
								className={classNames(
									"rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
									elasticSearchImportIsLoading ||
										SfToEsImportJobExists
										? "bg-slate-600 hover:bg-slate-500 cursor-wait"
										: ""
								)}
								onClick={() =>
									openModalWithAction(confirmImportFromSfToEs)
								}
								disabled={
									elasticSearchImportIsLoading ||
									SfToEsImportJobExists
								}
							>
								{elasticSearchImportIsLoading
									? "Importing..."
									: SfToEsImportJobExists
									? "Previous job hasn't finished"
									: "Import Homes"}
							</button>
							<button
								type="button"
								className={classNames(
									"ml-10 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
									syncWithMyAgedCareIsLoading ||
										myAgedCareToSalesforceJobRunning
										? "bg-slate-600 hover:bg-slate-500 cursor-wait"
										: ""
								)}
								onClick={() =>
									openModalWithAction(
										confirmSyncMyAgedCareData
									)
								}
								disabled={
									syncWithMyAgedCareIsLoading ||
									myAgedCareToSalesforceJobRunning
								}
							>
								{syncWithMyAgedCareIsLoading
									? "Importing..."
									: myAgedCareToSalesforceJobRunning
									? "Previous Sync My Aged Care Job hasn't finished"
									: "Sync Data from MyAgedCare"}
							</button>
						</div>
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-5">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<button
									type="button"
									className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
									onClick={() => {
										mutate();
									}}
									disabled={isValidating}
								>
									{isValidating ? "Loading.." : "Refresh"}
								</button>
								<table className="min-w-full divide-y divide-gray-300">
									<thead>
										<tr>
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
											>
												Type
											</th>
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
											>
												Job Description
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:block"
											>
												UUID
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Status
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Message
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Initiated at
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Completed at
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200">
										{data.data.Items.map((job) => (
											<tr key={job.uuid}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{job.jobType}
												</td>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{job.jobDescription}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden md:block">
													{job.uuid}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{job.status}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{job?.message}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{job.initiated_timestamp}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{job.completed_timestamp}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				)}
			</div>

			<ConfirmationModal
				isOpen={isModalOpen}
				message="Are you sure you want to continue?"
				onConfirm={handleConfirm}
				onAbort={handleAbort}
			/>
		</>
	);
};

export default SyncStatus;
