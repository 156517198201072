import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Config } from "./config/config";

import { AuthProvider } from "react-oauth2-code-pkce";

import "./App.css";
import AdminHomePage from "./components/AdminHomePage";
import UploadInvoice from "./components/uploadInvoice";
import LoginLogout from "./components/loginLogout";
import CarePayQuestionnaire from "./components/CarePayQuestionnaire";
import ReviewQuestionnaire from "./components/ReviewQuestionnaire";
import AddCarepayCustomer from "./components/addCarepayCustomer";
import UpdateDAP from "./components/updateDAP";
import SyncStatus from "./components/SyncStatus";
import { SWRConfig } from "swr";
import { fetcher } from "./utilities/fetcher";

const conf = new Config(
	process.env.REACT_APP_ENVIRONMENT_NAME
		? process.env.REACT_APP_ENVIRONMENT_NAME
		: "staging"
);
const authConfig = {
	clientId: conf.vars.IDENTITY_PROVIDER_CLIENT_ID,
	authorizationEndpoint: `${conf.vars.IDENTITY_PROVIDER_OAUTH_ENDPOINT}/authorize`,
	tokenEndpoint: `${conf.vars.IDENTITY_PROVIDER_OAUTH_ENDPOINT}/token`,
	redirectUri: conf.vars.IDENTITY_PROVIDER_REDIRECT_URI,
	scope: conf.vars.IDENTITY_PROVIDER_DEFAULT_SCOPES.join(" "),
	autoLogin: true,
};

function App() {
	return (
		<SWRConfig
			value={{
				fetcher,
				onError: (error) => {
					console.log("SWRConfigError: ", error);
				},
			}}
		>
			<AuthProvider authConfig={authConfig}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<AdminHomePage />} />
						<Route
							path="/invoice"
							element={
								<>
									<LoginLogout /> <UploadInvoice />
								</>
							}
						/>
						<Route
							path="/carepayQuestionnaire"
							element={
								<>
									<LoginLogout />
									<CarePayQuestionnaire />
								</>
							}
						/>
						<Route
							path="/reviewQuestionnaire"
							element={
								<>
									<LoginLogout />
									<ReviewQuestionnaire />
								</>
							}
						/>
						<Route
							path="/addCarepayCustomer"
							element={
								<>
									<LoginLogout /> <AddCarepayCustomer />
								</>
							}
						/>
						<Route
							path="/updateDap"
							element={
								<>
									<LoginLogout />
									<UpdateDAP />
								</>
							}
						/>
						<Route
							path="/viewSync"
							element={
								<>
									<SyncStatus />
								</>
							}
						/>
					</Routes>
				</BrowserRouter>
			</AuthProvider>
		</SWRConfig>
	);
}

export default App;
