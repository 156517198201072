import { IConfig } from './config';

export const PRODUCTION_CONFIG: IConfig = {
  REGION: "ap-southeast-2",
  IDENTITY_PROVIDER_CLIENT_ID: "pe35forhsc72l0ib7mn2h5qlt",
  IDENTITY_PROVIDER_OAUTH_ENDPOINT: 'https://care360-admin.auth.ap-southeast-2.amazoncognito.com/oauth2',
  IDENTITY_PROVIDER_REDIRECT_URI: 'https://admin.app.care360.com.au/',
  IDENTITY_PROVIDER_DEFAULT_SCOPES: ['openid', 'email', 'profile'],

  REACT_APP_CAREPAY_API_URL: "http://carepay.app.care360.com.au:3095",
  CARE360_WEBSITE_URL: "https://app.care360.com.au",

  BASE_URL: "https://admin.app.care360.com.au"
};
