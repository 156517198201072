import React, { useContext } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import Button from '@mui/material/Button';

import '../App.css';


const LoginLogout = () => {
  const { token, login, logOut } = useContext(AuthContext);

  const handleLogOut = () => {
    logOut();
  }
  return (
    <>
     {
        (
          (token === '') && (
            <Button
              variant="contained"
              onClick={login}
            >
              Log In
            </Button>
          )
        )
      }
      {
        (token !== '') && (
          <Button
            variant="contained"
            onClick={handleLogOut}
          >
            Log Out
          </Button>
        )
      }
    </>
  );
}

export default React.memo(LoginLogout);
